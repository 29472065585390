.subjectTab-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin-top: 4px;
    z-index: 10;
    overflow: hidden;
    cursor: pointer;
}

.subjectTab {
    min-width: 922px;
    height: 64px;
    border-radius: 7px;
    z-index: 10;
    background-color: rgba(234, 57, 44);
    font-size: 24px;font-weight: 500; color:#4F4F4F;
}

.subjectTab-add {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;font-weight: 500; color:#ffffff;
    width: fit-content;
    padding: 3px;
    background-color: rgb(234, 57, 44, 0.3);
    border-radius: 15px;
    margin-top: 20px;
    transition: 0.3s;
    cursor: pointer;
}

.subjectTab-add:hover {
    background-color: #EA392C;
}

.subjectTab-trans {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.subjectTab-subTabs {
    margin-top: 20px;
    margin-bottom: 20px;
}

.subjectTab-arrow-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    right: 20px;
    height: 80px;
    width: 1px;
    border-top-left-radius: 17px;
    border-top-right-radius: 17px;
    user-select: none;
}

.subjectTab-image-title {
    width: 90%;
    text-align: center;
}

.subjectTab-arrow-wrapper-image-up {
    width: 23px;
    transition-duration: 0s;
    user-select: none;
}

.subjectTab-arrow-wrapper-image-down {
    width: 23px;
    transform: rotate(180deg);
    transition-duration: 0s;
}