.quizMaker {
  padding: 20px;
}

.quizMaker h1 {
  text-align: center;
}

.quiz-maker-form {
  width: 500px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.quiz-maker-form div {
  margin-bottom: 15px;
}

.quiz-maker-form label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.quiz-maker-form input[type="text"],
.quiz-maker-form input[type="number"],
.quiz-maker-form input[type="datetime-local"],
.quiz-maker-form input[type="file"] {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.quiz-maker-form button {
  display: inline-block;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.quiz-maker-form button:hover {
  background-color: #0056b3;
}

.question-section {
  border-top: 1px solid #ccc;
  padding-top: 20px;
  margin-top: 20px;
}

.option-section {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
}

.upload-section {
  margin-bottom: 20px;
}

#createQuizButton {
  margin-left: 20px;
}
