.main{
    width: 100vw;
    max-width: 100%;
    height: 1175px;
    display: flex;
    justify-content: center;
}

.main-dashboard{
    margin-top: 55px;
}

.main-lenta {
    margin-left: 46px;
    margin-top: 55px;
}