.grade-item {
  display: flex;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid #ccc;
  width: 930px;
}

.grade-item div:nth-child(2){
  margin-left: 10px;
  width: 80px;
  text-wrap: nowrap;
}

.grade-item div:nth-child(3){
  text-align: center;
  margin-left: 500px;
  width: 80px;
}

.grade-item div:nth-child(4){
  margin-left: 50px;
  text-align: center;
  width: 60px;
}

.grade-item div:nth-child(5){
  margin-left: 50px;
}

.grade-even {
  background-color: #FFF2F1 !important;
}

.grade-item-arrow {
  width: 12px;
  margin-left: 10px;
}

.grade-item-attempts {
  display: flex;
  align-items: center;
  height: 43px;
  border-bottom: 1px solid #ccc;
  width: 930px;
  font-size: 14px; font-weight: 500; color:#4F4F4F;
}

.grade-item-attempts div:nth-child(1) {
  margin-left: 20px;
  color: #EA392C !important;
  text-decoration: underline;
  cursor: pointer;
  width: 100px;
}

.grade-item-attempts div:nth-child(2){
  margin-left: 500px;
  width: 60px;
  text-align: center;
}

.grade-item-attempts div:nth-child(3){
  margin-left: 80px;
}

.grade-item-attempts div:nth-child(4){
  margin-left: 250px;
}

.grade-item-attempts div:nth-child(5){
  margin-left: 40px;
}