.fileMaker-wrapper {
  position: fixed;
  z-index: 50;
  width: 100vw;
  height: 100%;
  left: 0;
  top: 0;
  overflow: auto;
  background-color: rgba(
      0,
      0,
      0,
      0.4
  );
}

.fileMaker-close {
  z-index: 50;
  position: absolute;
  height: 100%;
  width: 100%;
}

.fileMaker-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 51;
}

.fileMaker {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  margin-top: 30px;
  background-color: white;
  width: 600px;
  min-height: 300px;
  z-index: 51;
}
  
  .fileMaker h1 {
    text-align: center;
  }
  
  .file-maker-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .file-maker-form div {
    margin-bottom: 15px;
  }
  
  .file-maker-form label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .file-maker-form input[type="text"],
  .file-maker-form input[type="number"],
  .file-maker-form input[type="datetime-local"],
  .file-maker-form input[type="file"] {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .file-maker-form button {
    display: inline-block;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .file-maker-form button:hover {
    background-color: #0056b3;
  }
  
  .upload-section {
    margin-bottom: 20px;
  }
  