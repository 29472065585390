.postCreator-post {
    width: 955px;
    height: 323px;
    margin-bottom: 40px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    z-index: 2;
}

.postCreator-delete {
    align-self: center;
    background-color: rgba(234, 57, 44, 1);
    width: 150px;
    height: 30px;
    border-radius: 10px;
    border: none;
    font-size: 16px; font-weight: 500; color:#ffffff;
}

.postCreator-post:nth-child(2) {
    margin-top: 40px;
}

.postCreator-post-open:nth-child(2) {
    margin-top: 40px;
}

.postCreator-post-open {
    width: 955px;
    height: 352px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    z-index: 2;
}

.postCreator-post-image {
    height: 323px;
    width: 100%;
    z-index: 2;
    background-position: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

.postCreator-post-image-trans {
    min-height: 100%;
    width: 100%;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    background-color: rgb(255, 255, 255, 0.3);
}

.postCreator-post-image-title {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 33px; font-weight: 400; color:#ffffff;
    top: 160px;
    min-width: 400px;
    min-height: 74px;
    background-color: #EA392C;
}

.postCreator-arrow-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 843px;
    top: 169px;
    height: 80px;
    width: 56px;
    border-top-left-radius: 17px;
    border-top-right-radius: 17px;
    background-color: #EA392C;
    user-select: none;
}

.postCreator-arrow-wrapper-image-up {
    width: 23px;
    transition-duration: 0.5s;
    user-select: none;
}

.postCreator-arrow-wrapper-image-down {
    width: 23px;
    transform: rotate(180deg);
    transition-duration: 0.5s;
}

.postCreator-post-content {
    height: 300px;
    width: 100%;
    background-color: #EA392C;
    z-index: 1;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}
