/* groupMembers.css */

.group-members {
    padding: 20px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.groupMembers {
    width: 100vw;
    max-width: 100%;
    display: flex;
    justify-content: center;
}
  
.groupMembers-dashboard {
    margin-top: 55px;
}
  
.groupMembers-wrapper {
    width: 1030px;
    min-height: 600px;
    -webkit-box-shadow: 0px 2px 20px -11px rgba(77,77,77,1);
    -moz-box-shadow: 0px 2px 20px -11px rgba(77,77,77,1);
    box-shadow: 0px 2px 20px -11px rgba(77,77,77,1);
    border-radius: 30px;
    margin-left: 46px;
    margin-top: 55px;
    display: flex;
    flex-direction: column;
}

.groupMembers-create {
    width: 170px;
    position: relative;
    font-size: 16px; font-weight: 600; color:#ffffff;
    cursor: pointer;
    height: 45px;
    background-color: #EA392C;
    border-radius: 15px;
    border: none;
}    

.groupMembers-create:hover {
    background-color: #ea392cb3;
}

.groupMembers-addStudent {
    width: 230px;
    position: relative;
    left: 20px;
    font-size: 16px; font-weight: 600; color:#ffffff;
    cursor: pointer;
    height: 45px;
    background-color: #EA392C;
    border-radius: 15px;
    border: none;
}    

.groupMembers-addStudent:hover {
    background-color: #ea392cb3;
}

.group-members h1 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.group-members ul {
    list-style: none;
    padding: 0;
}

.group-members li {
    background: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.group-members li:last-child {
    margin-bottom: 0;
}

.group-members li span {
    font-size: 16px;
    color: #555;
}

.group-members li span:nth-child(1) {
    font-size: 16px;
    color: #555;
    min-width: 200px;
    margin-left: 20px;
}

.group-members li span:nth-child(2) {
    font-size: 16px;
    color: #555;
    width: 200px;
    margin-left: 130px;
    text-align: center;
}

.group-members li span:nth-child(3) {
    font-size: 16px;
    margin-right: 10px;
    color: #555;
    width: 300px;
    margin-left: 120px;
    text-align: right;
}

.group-members li button {
    font-size: 14px; font-weight: 600; color:#ffffff;
    height: 30px;
    cursor: pointer;
    background-color: #EA392C;
    border-radius: 5px;
    border: none;
}

.groups-list-button {
    width: 100px;
    margin-left: 35px;
}

.groups-edit-button {
    width: 40px;
    margin-left: 150px;
}

.groups-delete-button {
    width: 60px;
    margin-left: 35px;
}

.group-members li button:hover {
    background-color: #ea392cb3;
}

.groups-title {
    font-weight: 600;
}

.group-members .loading,
.group-members .error {
    text-align: center;
    color: #666;
    font-size: 18px;
}
