.math {
    width: 100vw;
    max-width: 100%;
    height: 1175px;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.math-dashboard{
    margin-top: 55px;
}

.math-content {
    margin-left: 45px;
}

.math-content-lessons {
    width: 1030px;
    height: 805px;
    -webkit-box-shadow: 0px 2px 20px -11px rgba(77,77,77,1);
    -moz-box-shadow: 0px 2px 20px -11px rgba(77,77,77,1);
    box-shadow: 0px 2px 20px -11px rgba(77,77,77,1);
    border-radius: 30px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

a {
    color: inherit !important;
    text-decoration: inherit !important;
}