.quizReview-page {
    display: flex;
    width: 100vw;
    max-width: 100%;
    justify-content: center;
}

.quizReview-task {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    min-height: 600px;
    margin-top: 20px;
}

.quizReview-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    min-height: 800px;
    border-bottom: 1px solid #6A6A6A;
}

.quizReview-content-explanation {
    min-height: 600px;
    margin-top: 20px;
    min-width: 600px;
    background-image: url("../../../public/assets/images/ramka-test.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content-explanation-title {
    margin-top: 5px;
}

.content-explanation-text {
    width: 80%;
    height: fit-content;
}

.quizReview-mdxEditor  {
  pointer-events: none;
  text-align: center;
}

.quizReview-content-explanation ._editImageToolbar_uazmk_935 {
  visibility: hidden;
}

.quizReview-mdxEditor ._imageWrapper_uazmk_922 {
  pointer-events: none;
}

._imageWrapper_uazmk_922 img {
  max-width: 100% !important;
}

.quizReview-task-question-mark {
    display: flex;
    align-items: center;
    min-height: 50px;
    background-color: #FFF2F1;
}

.quizReview-task-question-mark img {
    width: 16px;
    margin-top: 5px;
    margin-left: 10px;
}

.quizReview-task-question-mark-index {
    width: 40px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F9C4C0;
}

.quizReview-task-question-mark-text {
    font-size: 18px; font-weight: 400; color:#4F4F4F;
    margin-left: 10px;
}

.quizReview-task-result-correct {
    width: 650px;
    height: 80px;
    border: 1px solid #47EA2C;
    -webkit-box-shadow: 0px 0px 25px -16px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 25px -16px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 25px -16px rgba(0,0,0,0.75);
    border-radius: 15px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
}

.quizReview-task-result-correct div:nth-child(1) {
  background-color: #c0ff8f4c;
  border: 2px solid #47EA2C;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  font-size: 16px; font-weight: 600; color:#00AE11;
}

.quizReview-task-result-wrong{
  width: 650px;
  height: 80px;
  border: 1px solid #EA392C;
  -webkit-box-shadow: 0px 0px 25px -16px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 25px -16px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 25px -16px rgba(0,0,0,0.75);
  border-radius: 15px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
}

.quizReview-task-result-wrong div:nth-child(1) {
  background-color: #ea392c41;
  border: 2px solid #EA392C;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.quizReview-task-result-wrong div:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 480px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.quizReview-task-nav {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.quizReview-task-nav-button-l {
    left: 200px;
    position: relative;
    background-color: #F9C4C0;
    border: none;
    border-radius: 15px;
    padding-left: 12px;
    padding-right: 12px;
}

.quizReview-task-nav-button-l:nth-child(3) {
    left: 250px;
}

.quizReview-task-nav-button-l:hover {
    cursor: pointer;
    background-color: #fed8d6;
}

.quizReview-task-nav-button-q {
    background-color: #FF978F;
    border: none;
    cursor: pointer;
    border-radius: 15px;
    padding: 6px;
    min-width: 150px;
}

.nav-button-text {
     font-size: 18px; font-weight: 400; color:#4F4F4F;
}

.popup {
    position: relative;
    display: inline-block;
    cursor: pointer;
    user-select: none;
  }
  
  .popup .popuptext {
    visibility: hidden;
    min-width: 200px;
    width: fit-content;
    max-width: 300px;
    word-wrap: break-word;
    height: fit-content;
    background-color: #555;
    color: #ffffff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    right: 100%;
    bottom: 15%;
    margin-left: -80px;
    opacity: 0;
    transition: opacity 0.5s ease, visibility 0.5s;
  }
  
  .popup .popuptext::after {
    content: "";
    position: absolute;
    top: 45%;
    left: 100%;
    transform: rotate(270deg);
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .popup .show {
    visibility: visible;
    opacity: 1;
  }

.question-nav-container {
    position: relative;
    display: inline-block;
  }
  
  .question-nav {
    position: absolute;
    bottom: 105%; /* Position above the button */
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    max-height: 0;
    overflow: hidden;
  }
  
  .question-nav.show {
    opacity: 1;
    visibility: visible;
    max-height: 500px;
    width: 200px;
  }
  
  .question-nav ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .question-nav li {
    cursor: pointer;
    width: 44px;
    padding: 10px 5px;
    text-align: center;
     font-size: 14px; font-weight: 400; color:#4F4F4F;
  }

  .question-nav-item-active {
    background-color: #F9C4C0;
  }

  .question-nav li:hover {
    background-color: #fcc5c16b;
  }