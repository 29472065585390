.grade {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  min-width: 950px;
  min-height: 450px;
}
  
.grade-headers {
  display: flex;
  flex-direction: row;
  font-size: 16px; font-weight: 500; color:#4F4F4F;
}

.grade-headers div:nth-child(2) {
  margin-left: 555px;
}

.grade-headers div:nth-child(3) {
  margin-left: 65px;
}

.grade-headers div:nth-child(4) {
  margin-left: 30px;
}


.grade-ramka {
  width: 100%;
  height: 100%;
  
}

.grade-ramka-background {
  background-color: #F9C4C0;
}

.grade-ramka p {
  position: relative;
  height: 0;
  left: 30px;
  top: 13px;
  font-size: 16px; font-weight: 500; color:#4F4F4F;
}

.grade-ramka p img{
  margin-right: 5px;
  margin-bottom: 2px;
}

.grade-items {
  margin-top: 50px;
  margin-left: 20px;
  background-color: white;
}
  
.grade-result {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 40px;
  height: 80px;
  font-size: 16px; font-weight: 500; color:#313131;
}

.grade-result div:nth-child(2) {
  margin-left: 640px;
}

.satGrade-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}

.satGrade {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 273px;
  height: 310px;
  -webkit-box-shadow: 0px 0px 18px -9px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 18px -9px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 18px -9px rgba(0,0,0,0.75);
  margin-right: 18px;
  margin-left: 18px;
  margin-top: 32px;
  border-radius: 12px;
}

.satGrade-title {
  font-size: 14px; font-weight: 600; color:#ffffff;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  display: flex;
  width: 100%;
  height: 37px;
  background-color: #EA392C;
  align-items: center;
  padding-left: 20px;
}

.satGrade-score {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 30px;
}

.satGrade-score div:nth-child(1){
  font-size: 16px; font-weight: 600; color:#000000;
}

.satGrade-score div:nth-child(2){
  font-size: 32px; font-weight: 600; color:#000000;
}

.satGrade-score div:nth-child(3){
  font-size: 13px; font-weight: 400; color:#000000;
}

.satGrade-sectionScore {
  width: 82%;
  display: flex;
  flex-direction: row;
}

.satGrade-sectionScore:nth-child(odd) {
  margin-top: 20px;
  border-bottom: 1px solid #E3E3E3;
}

.satGrade-sectionScore div:nth-child(1) {
  font-size: 16px; font-weight: 400; color:#000000;
  width: 112px;
}

.satGrade-sectionScore div:nth-child(2) {
  width: 112px;
  text-align: end;
  font-size: 16px; font-weight: 600; color:#000000;
}

.satGrade-button {
  align-self: center;
  margin-top: 20px;
  width: 241px;
  height: 37px;
  border: 2px solid #EA392C;
  border-radius: 12px;
  background-color: #ffffff;
  font-size: 14px; font-weight: 600; color:#EA392C;
}
  