.lenta {
    width: 1030px;
    height: 1003px;
    -webkit-box-shadow: 0px 2px 20px -11px rgba(77,77,77,1);
    -moz-box-shadow: 0px 2px 20px -11px rgba(77,77,77,1);
    box-shadow: 0px 2px 20px -11px rgba(77,77,77,1);
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.lenta::-webkit-scrollbar {
    display: none;
}

.lenta-countdown {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 955px;
    min-height: 182px;
    height: 182px;
    background-color: #EA392C;
    border-radius: 30px;
    margin-top: 45px;
    font-size: 24px; font-weight: 300; color:#ffffff;
}

.lenta-countdown-time {
    display: flex;
}

.lenta-countdown-time table {
    border-collapse: collapse;
    height: 50px;
    border: none;
}

.lenta-countdown-time th {
    text-align: center;
   width: 150px;
   padding: 0;
   font-size: 40px; font-weight: 500;
}

.lenta-countdown-time td {
    text-align: center;
    padding: 0;
    width: 150px;
    font-size: 24px; font-weight: 300;
 }