.quiz-page {
    display: flex;
    width: 100vw;
    max-width: 100%;
    justify-content: center;
}

.quiz-startScreen {
    position: fixed;
    width: 100%;
    min-height: 100%;
    top: 0;
    background-color: white;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.quiz-startScreen div:nth-child(1) {
    margin-top: 150px;
    font-size: 32px; font-weight: 600; color:#000000;
}

.quiz-startScreen div:nth-child(2) {
    margin-top: 10px;
    font-size: 20px; font-weight: 300; color:#000000;
}

.quiz-startScreen button{
    align-self: center;
    margin-top: 100px;
    width: 217px;
    height: 56px;
    border: none;
    border-radius: 16px;
    background-color: #EA392C;
    font-size: 16px; font-weight: 600; color:#ffffff;
}

.quiz-startScreen button:hover{
    background-color: #B83B31;
}

.quiz-task {
    display: flex;
    flex-direction: column;
    width: 1090px;
    min-height: 600px;
    margin-top: 20px;
}

.quiz-task-question {
    min-height: 520px;
    width: 100%;
    border-bottom: 1px solid #6A6A6A;
}

.quiz-task-question-content {
    min-height: 100%;
    width: 95%;
    padding-top: 20px;
}

.quiz-task-question-mark {
    display: flex;
    align-items: center;
    min-height: 50px;
    background-color: #FFF2F1;
}

.quiz-task-question-mark img {
    width: 16px;
    margin-top: 5px;
    margin-left: 10px;
}

.quiz-task-question-mark-index {
    width: 40px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F9C4C0;
}

.quiz-task-question-mark-text {
     font-size: 18px; font-weight: 400; color:#4F4F4F;
    margin-left: 10px;
}

.quiz-task-nav {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.quiz-addQuestion {
    width: 200px;
    font-size: 14px; font-weight: 600; color:#ffffff;
    cursor: pointer;
    height: 45px;
    background-color: #EA392C;
    border-radius: 15px;
    border: none;
    margin-left: 150px;
    margin-top: 3px;
}

.quiz-addQuestion:hover {
    background-color: #ea392cb3;
}

.quiz-deleteQuestion {
    width: 200px;
    font-size: 14px; font-weight: 600; color:#ffffff;
    cursor: pointer;
    height: 45px;
    background-color: #EA392C;
    border-radius: 15px;
    border: none;
    margin-left: 150px;
    margin-top: 3px;
}

.quiz-deleteQuestion:hover {
    background-color: #ea392cb3;
}

.quiz-task-nav-button-r:first-child {
    margin-right: 35px;
}

.quiz-task-nav-button-r {
    margin-right: 280px;
    background-color: #F9C4C0;
    border: none;
    border-radius: 15px;
    padding-left: 12px;
    padding-right: 12px;
}

.quiz-task-nav-button-r:hover {
    cursor: pointer;
    background-color: #fed8d6;
}

.quiz-task-nav-button-l:nth-child(even) {
    margin-left: 100px
}

.quiz-task-nav-button-l {
    margin-left: 35px;
    background-color: #F9C4C0;
    border: none;
    border-radius: 15px;
    padding-left: 12px;
    padding-right: 12px;
}

.quiz-task-nav-button-l:hover {
    cursor: pointer;
    background-color: #fed8d6;
}

.quiz-task-nav-button-q {
    background-color: #FF978F;
    border: none;
    cursor: pointer;
    border-radius: 15px;
    padding: 6px;
    min-width: 150px;
}

.nav-button-text {
    font-size: 18px; font-weight: 400; color:#4F4F4F;
}

.quiz-rightBoard {
    height: 500px;
    width: 100px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F9C4C0;
}

.quiz-rightBoard div:first-child {
    margin-top: 12px;
     font-size: 24px; font-weight: 400; color:#4F4F4F;
}

.quiz-rightBoard div:nth-child(n+2) {
    margin-top: 40px;
    display: flex;
    width: 80px;
    height: 80px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
     font-size: 14px; font-weight: 400; color:#4F4F4F;
}

.quiz-rightBoard div:nth-child(n+2) img {
    width: 35px;
    margin-bottom: 5px;
}

.quiz-rightBoard div:nth-child(n+2):hover {
    background-color: rgba(255, 235, 233, 0.8)
}

/* pop up hint animation */

.popup {
    position: relative;
    display: inline-block;
    cursor: pointer;
    user-select: none;
  }
  
  .popup .popuptext {
    visibility: hidden;
    min-width: 200px;
    width: fit-content;
    max-width: 300px;
    word-wrap: break-word;
    height: fit-content;
    background-color: #555;
    color: #ffffff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    right: 100%;
    bottom: 15%;
    margin-left: -80px;
    opacity: 0;
    transition: opacity 0.5s ease, visibility 0.5s;
  }
  
  .popup .popuptext::after {
    content: "";
    position: absolute;
    top: 45%;
    left: 100%;
    transform: rotate(270deg);
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .popup .show {
    visibility: visible;
    opacity: 1;
  }

.question-nav-container {
    position: relative;
    display: inline-block;
  }
  
  .question-nav {
    position: absolute;
    bottom: 105%; /* Position above the button */
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    max-height: 0;
    overflow: hidden;
  }
  
  .question-nav.show {
    opacity: 1;
    visibility: visible;
    max-height: 500px;
    width: 200px;
  }
  
  .question-nav ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .question-nav li {
    cursor: pointer;
    width: 44px;
    padding: 10px 5px;
    text-align: center;
     font-size: 14px; font-weight: 400; color:#4F4F4F;
  }

  .question-nav-item-active {
    background-color: #F9C4C0;
  }

  .question-nav li:hover {
    background-color: #fcc5c16b;
  }