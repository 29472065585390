.sat-page {
    display: flex;
    width: 100vw;
    max-width: 100%;
    justify-content: center;
}

.sat-subject-title {
    font-size: 26px;
    font-weight: 500;
    margin-left: 500px;
}

.sat-addQuestion {
    width: 200px;
    font-size: 14px; font-weight: 600; color:#ffffff;
    cursor: pointer;
    height: 45px;
    background-color: #EA392C;
    border-radius: 15px;
    border: none;
    margin-left: 250px;
    margin-top: 3px;
}

.sat-addQuestion:hover {
    background-color: #ea392cb3;
}

.sat-deleteQuestion {
    width: 200px;
    font-size: 14px; font-weight: 600; color:#ffffff;
    cursor: pointer;
    height: 45px;
    background-color: #EA392C;
    border-radius: 15px;
    border: none;
    margin-left: 100px;
    margin-top: 3px;
}

.sat-deleteQuestion:hover {
    background-color: #ea392cb3;
}

.sat-task {
    display: flex;
    flex-direction: column;
    width: 1090px;
    min-height: 600px;
    margin-top: 20px;
}

.sat-task-question {
    min-height: 520px;
    width: 100%;
    border-bottom: 1px solid #6A6A6A;
}

.sat-task-question-content {
    min-height: 100%;
    width: 95%;
    padding-top: 20px;
}

.sat-task-question-mark {
    display: flex;
    align-items: center;
    min-height: 50px;
    background-color: #FFF2F1;
}

.sat-task-question-mark img {
    width: 16px;
    margin-top: 5px;
    margin-left: 10px;
}

.sat-task-question-mark-index {
    width: 40px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F9C4C0;
}

.sat-task-question-mark-text {
     font-size: 18px; font-weight: 400; color:#4F4F4F;
    margin-left: 10px;
}

.sat-task-nav {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.sat-task-nav-button-r:first-child {
    margin-right: 35px;
}

.sat-task-nav-button-r {
    margin-right: 100px;
    background-color: #F9C4C0;
    border: none;
    border-radius: 15px;
    padding-left: 12px;
    padding-right: 12px;
}

.sat-task-nav-button-r:hover {
    cursor: pointer;
    background-color: #fed8d6;
}

.sat-task-nav-button-l {
    margin-left: 100px;
    background-color: #F9C4C0;
    border: none;
    border-radius: 15px;
    padding-left: 12px;
    padding-right: 12px;
}

.sat-task-nav-button-l:hover {
    cursor: pointer;
    background-color: #fed8d6;
}

.sat-task-nav-button-q {
    background-color: #FF978F;
    border: none;
    cursor: pointer;
    border-radius: 15px;
    padding: 6px;
    min-width: 150px;
}

.nav-button-text {
     font-size: 18px; font-weight: 400; color:#4F4F4F;
}

.sat-rightBoard {
    min-height: 500px;
    max-height: 570px;
    width: 100px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F9C4C0;
}

.sat-rightBoard div:first-child {
    margin-top: 12px;
     font-size: 24px; font-weight: 400; color:#4F4F4F;
}

.sat-rightBoard div:nth-child(n+2) {
    margin-top: 40px;
    display: flex;
    width: 80px;
    height: 80px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px; font-weight: 400; color:#4F4F4F;
}

.sat-rightBoard a {
    display: flex;
    width: 80px;
    height: 80px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px; font-weight: 400; color:#4F4F4F;
}

.sat-rightBoard div:nth-child(n+2) img {
    width: 35px;
    margin-bottom: 5px;
}

.sat-rightBoard div:nth-child(n+2):hover {
    background-color: rgba(255, 235, 233, 0.8)
}

/* pop up hint animation */

.popup {
    position: relative;
    display: inline-block;
    cursor: pointer;
    user-select: none;
  }

  .popup .popuptext {
    visibility: hidden;
    min-width: 200px;
    width: fit-content;
    max-width: 300px;
    word-wrap: break-word;
    height: fit-content;
    background-color: #555;
    color: #ffffff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    right: 100%;
    bottom: 15%;
    margin-left: -80px;
    opacity: 0;
    transition: opacity 0.5s ease, visibility 0.5s;
  }
  
  .popup .popuptext::after {
    content: "";
    position: absolute;
    top: 45%;
    left: 100%;
    transform: rotate(270deg);
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .popup .show {
    visibility: visible;
    opacity: 1;
  }

.question-nav-container {
    position: relative;
    display: inline-block;
  }
  
  .question-nav {
    position: absolute;
    bottom: 105%; /* Position above the button */
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    max-height: 0;
    overflow: hidden;
  }
  
  .question-nav.show {
    opacity: 1;
    visibility: visible;
    max-height: 500px;
    width: 200px;
  }
  
  .question-nav ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .question-nav li {
    cursor: pointer;
    width: 44px;
    padding: 10px 5px;
    text-align: center;
     font-size: 14px; font-weight: 400; color:#4F4F4F;
  }

  .question-nav-item-active {
    background-color: #F9C4C0;
  }

  .question-nav li:hover {
    background-color: #fcc5c16b;
  }

  .question-nav-item-answered {
    background-color: #00000014;
  }

  .question-nav-item-marked {
    background-color: #9500ff4a;
    border: 0.3px solid rgb(255, 255, 255);
  }

  /* Break Screen Container */
.break-screen {
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    color: #000000;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  
  .break-screen-wrap {
    display: flex;
    flex-direction: column;
    width: 70%;
  }

  /* Header and Warning */
  .break-screen__header {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 50px;
  }
  
  .break-screen__warning {
    color: #ff0000;
  }
  
  /* Description */
  .break-screen__description {
    font-size: 1.3rem;
    color: #000000;
    margin-bottom: 20px;
  }
  
  /* Timer Section */
  .break-screen__timer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .break-screen__timer {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 15px 20px;
    text-align: center;
    margin-bottom: 15px;
    display: inline-block;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .break-screen__timer-label {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 5px;
  }
  
  .break-screen__timer-value {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
  }
  
  /* Resume Button */
  .break-screen__resume-button {
    background-color: #ff6666;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .break-screen__resume-button:hover {
    background-color: #ff4c4c;
  }
  
  /* Reminder Section */
  .break-screen__reminder {
    text-align: left;
    margin-top: 30px;
  }
  
  .break-screen__reminder-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .break-screen__reminder-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .break-screen__reminder-list li {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .break-screen__reminder-list li strong {
    color: #000;
  }
  