.contentMaker-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contentMaker-title {
    margin-top: 20px;
    font-size: 28px;font-weight: 600; color:#000000;
}

.content-maker-form {
    width: 600px;
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.content-maker-form div {
    margin-bottom: 15px;    
}

.content-maker-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.content-maker-form input {
    width: calc(100% - 22px);
    padding: 10px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.content-maker-form button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    margin-right: 10px;
}

.content-maker-form button:hover {
    background-color: #0056b3;
}

.contentMaker-fail {
    text-align: center;
    font-size: 18px;font-weight: 400;color: #DD8A8A;
}