.postStyle {
    border: 1px solid #EA392C;
    width: 954px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    min-height: 200px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.post-mdxEditor {
    pointer-events: none;
    margin-top: 20px;
}

.postStyle ._editImageToolbar_uazmk_935 {
    visibility: hidden;
}

.postStyle ._imageWrapper_uazmk_922 {
    pointer-events: none;
}

.postTextStyle {
    font-size: 20px; font-weight: 300; color:#000000;
}

._imageWrapper_uazmk_922 img {
    max-width: 100% !important;
    max-height: 600px;
}

.postStyle button {
    align-self: center;
    margin-bottom: 10px;
    background-color: rgba(234, 57, 44, 1);
    width: 251px;
    height: 45px;
    border-radius: 15px;
    border: none;
    font-size: 18px; font-weight: 500; color:#ffffff;
}

