.testReview-task-question {
    min-height: 450px;
    width: 100%;
}

.testReview-task-question-content {
    height: 100%;
    width: 95%;
    padding-top: 20px;
    margin-bottom: 50px;
}

.testReview-task-question-content img {
    width: 500px;
}

.testReview-option {
    margin-top: 20px;
    width: 700px;
    height: 60px;
    display: flex;
    align-items: center;
    border-radius: 16px;
    border: 1px solid black;
}

.testReview-option span{
    margin-left: 10px;
}

.testReview-option:nth-child(2){
    margin-top: 50px !important;
}

.testReview-task-question .active {
    background-color: #FF978F;
}

.testReview-letter {
    min-width: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid black;
    margin-left: 10px;
}

.testReview-letter-correct {
    min-width: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 10px;
}

.testReview-letter-correct img {
    width: 100%;
    height: 100%;
}