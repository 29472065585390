.add-event-container {
    position: fixed;
    z-index: 50;
    width: 100vw;
    height: 100%;
    left: 0;
    top: 0;
    overflow: auto;
    background-color: rgba(
        0,
        0,
        0,
        0.4
    );
}

.add-event-close {
    z-index: 50;
    position: absolute;
    height: 100%;
    width: 100%;
}

.add-event-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 51;
}

.add-event-ramka {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    background-color: white;
    width: 600px;
    min-height: 600px;
    z-index: 51;
}

h1 {
    text-align: center;
    margin-top: 30px;
    color: #333;
}

.add-event-form {
    display: flex;
    flex-direction: column;
    width: 500px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.form-group textarea {
    resize: vertical;
}

.submit-button {
    padding: 10px 15px;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    margin-bottom: 20px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-button:hover {
    background-color: #0056b3;
}
