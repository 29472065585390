.SATtest-task-question {
    min-height: 520px;
    width: 100%;
    border-bottom: 1px solid #6A6A6A;
}

.SATtest-task-question-content {
    height: 100%;
    width: 95%;
    padding-top: 20px;
    margin-bottom: 50px;
}

.SATtest-task-question-content img{
    width: 500px;
}

.SATtest-option-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
}

.SATtest-option-cross {
    margin-left: 20px;
    font-size: 16px; font-weight: 600; color: black;
    text-decoration: underline;
    cursor: pointer;
}

.option-crossline {
    position: absolute;
    background-color: black;
    height: 5px;
    width: 924px;
    z-index: 10;
}

.option-crossed {
    border: 2px solid #8b8b8bbe !important;
}

.option-crossed .SATtest-letter {
    border: 2px solid #8b8b8bbe !important;
    color: #8b8b8bbe !important;
}

.option-crossed span {
    color: #8b8b8bbe !important
}

.SATtest-option {
    width: 925px;
    min-height: 60px;
    display: flex;
    align-items: center;
    border-radius: 16px;
    border: 2px solid black;
}

.SATtest-option span{
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 85%;
    
}

.SATtest-option textarea {
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: none;
    overflow: auto;
    outline: none;
    width: 90%;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    resize: none;
}

.SATtest-option-wrapper button{
    width: 60px;
    font-size: 14px; font-weight: 600; color:#ffffff;
    cursor: pointer;
    height: 30px;
    background-color: #EA392C;
    border-radius: 10px;
    border: none;
    margin-left: 18px;
}

.SATtest-button {
    width: 140px;
    font-size: 14px; font-weight: 600; color:#ffffff;
    cursor: pointer;
    margin-top: 20px;
    height: 35px;
    background-color: #EA392C;
    border-radius: 12px;
    border: none;
    margin-left: 18px;
}

.SATtest-option:nth-child(2){
    margin-top: 50px !important;
}

.SATtest-task-question .active {
    background-color: #FF978F;
}

.SATtest-letter {
    min-width: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid black;
    margin-left: 10px;
}

.SATtest-mdxEditor  {
    pointer-events: none;
  }
  
.SATtest-task-question-content ._editImageToolbar_uazmk_935 {
    visibility: hidden;
}

.SATtest-task-question-content ._imageWrapper_uazmk_922 {
    pointer-events: none;
}