.practiceTest {
    display: flex;
    flex-direction: column;
    width: 306px;
    height: 520px;
    -webkit-box-shadow: 0px 0px 18px -9px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 18px -9px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 18px -9px rgba(0,0,0,0.75);
    margin-right: 18px;
    margin-left: 18px;
    margin-top: 32px;
    border-radius: 12px;
}

.practiceTest-edit {
    display: flex;
    flex-direction: column;
    width: 306px;
    height: 206px;
    -webkit-box-shadow: 0px 0px 18px -9px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 18px -9px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 18px -9px rgba(0,0,0,0.75);
    margin-right: 18px;
    margin-left: 18px;
    margin-top: 32px;
    border-radius: 12px;
}

.practiceTest-title {
    font-size: 20px; font-weight: 600; color:#4F4F4F;
    align-self: center;
    margin-top: 15px;
}

.practiceTest-extendTime-item {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-top: 20px;
}

.practiceTest-extendTime-item-title {
    font-size: 14px; font-weight: 600; color:#000000;
}

.practiceTest-extendTime-item-list {
    display: flex;
    flex-direction: row;
}

.practiceTest-extendTime-item-choose{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 37px;
    border-radius: 12px;
    border: 1px solid #EA392C;
    background-color: #FF978F;
    font-size: 14px; font-weight: 600; color:#ffffff;
    margin-right: 10px;
    margin-top: 5px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.practiceTest .active {
    background-color: #ff5d52 !important;
}

.practiceTest-date-wrapper {
    margin-top: 10px;
}

.practiceTest-date-wrapper-admin {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.practiceTest-date {
    margin-left: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    width: 300px;
}

.practiceTest-date div:nth-child(1) {
    font-size: 15px; font-weight: 400; color:#4F4F4F;
    width: 100px;
}

.practiceTest-date div:nth-child(2) {
    font-size: 15px; font-weight: 600; color:#4F4F4F;
}

.practiceTest-button {
    align-self: center;
    margin-top: 20px;
    width: 90%;
    height: 56px;
    border: none;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: #EA392C;
    font-size: 16px; font-weight: 600; color:#ffffff;
}

.practiceTest-button:hover {
    background-color: #ea392cb6;
}

.practiceTest-button-admin {
    width: 90%;
    align-self: center;
    height: 56px;
}

.practiceTest-button-admin button {
    align-self: center;
    margin-top: 20px;
    width: 50%;
    height: 56px;
    border: none;
    background-color: #EA392C;
    font-size: 16px; font-weight: 600; color:#ffffff;
}

.practiceTest-button-admin button:hover {
    background-color: #ea392cb6;
}

.practiceTest-button-admin button:nth-child(1) {
    border-top-left-radius: 12px;
}

.practiceTest-button-admin button:nth-child(2) {
    border-left: 1px solid rgb(255, 255, 255);
    border-top-right-radius: 12px;

}

.practiceTest-date-wrapper-admin button {
    align-self: center;
    margin-top: 20px;
    width: 50%;
    height: 56px;
    border-radius: 15px;
    border: none;
    background-color: #EA392C;
    font-size: 16px; font-weight: 600; color:#ffffff;
}

.practiceTest-date-wrapper-admin button:hover {
    background-color: #ea392cb6;
}

.practiceTest-popUp {
    position: fixed;
    z-index: 51;
    width: 100vw;
    height: 100%;
    left: 0;
    top: 0;
    overflow: auto;
    background-color: rgba(
        0,
        0,
        0,
        0.4
    );
}

.practiceTest-popUp-close {
    z-index: 51;
    position: absolute;
    height: 100%;
    width: 100%;
}

.practiceTest-popUp-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 52;
}

.practiceTest-popUp-ramka {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background-color: white;
    width: 630px;
    min-height: 300px;
    z-index: 52;
}

.practiceTest-popUp label {
    font-size: 20px;
    color: #555;
    margin-bottom: 20px;
    width: 150px;
}

.practiceTest-popUp select {
    padding: 10px;
    font-size: 18px;
    width: 280px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.practiceTest-popUp input {
    padding: 10px;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.practiceTest-popUp button {
    padding: 10px 15px;
    margin-top: 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.practiceTest-popUp2 {
    position: fixed;
    z-index: 50;
    width: 100vw;
    height: 100%;
    left: 0;
    top: 0;
    overflow: auto;
    background-color: rgba(
        0,
        0,
        0,
        0.4
    );
}

.practiceTest-popUp2-close {
    z-index: 50;
    position: absolute;
    height: 100%;
    width: 100%;
}

.practiceTest-popUp2-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 51;
}

.practiceTest-popUp2-ramka {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background-color: white;
    width: 700px;
    height: 400px;
    z-index: 51;
}

.practiceTest-popUp2-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
}

.practiceTest-popUp2-date {
    display: flex;
    flex-direction: column;
    width: 300px;
    font-size: 18px;
    color: #555;
}

.practiceTest-popUp2 label {
    font-size: 20px;
    color: #555;
    min-width: 150px;
}

.practiceTest-popUp2 button {
    width: 100px;
    height: 35px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.practiceTest-popUp2-delete-button {
    margin-left: 10px;
}

.practiceTest-popUp2 button:hover {
    background-color: #007bffac;
}

.practiceTest-popUp2-add-button {
    margin-top: 30px;
    width: 200px !important;
    height: 45px !important;
}