.postConstructor {
    position: fixed;
    z-index: 45;
    width: 100vw;
    height: 100%;
    left: 0;
    top: 0;
    overflow: auto;
    background-color: rgba(
        0,
        0,
        0,
        0.4
    );
}

.postConstructor-close {
    z-index: 45;
    position: absolute;
    height: 100%;
    width: 100%;
}

.postConstructor-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 46;
}

.postConstructor-inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    margin-top: 50px;
    background-color: white;
    width: 700px;
    min-height: 600px;
    z-index: 46;
}

.pc-title {
    font-size: 20px; font-weight: 300; color:#000000;
    margin-top: 10px;
}

.pc-title-bar {
    margin-top: 15px;
    width: 300px;
    height: 23px;
    padding: 5px;
    border: 1px solid black !important;
    border-radius: 5px;
    font-size: 16px; font-weight: 400; color:#000000;
}

.postConstructor-menu input:focus {
    border: 1px solid black !important;
}

.pc-upload-image {
    display: flex;
    height: 100px;
}

.pc-image-button {
    margin-top: 15px;
    width: 100px;
    height: 35px;
    border: none;
    border-radius: 15px;
    background-color: #EA392C;
    font-size: 14px; font-weight: 400; color:#ffffff;
}

.pc-backImg-preview {
    width: 150px;
    max-height: 100px;
    margin-top: 13px;
    margin-left: 20px;
}

.pc-description-bar {
    margin-top: 15px;
    width: 700px;
    height: 250px;
    overflow: auto;
    resize: none;
    font-size: 16px; font-weight: 400; color:#000000;
}

.pc-submit {
    align-self: center;
    margin-top: 30px;
    margin-bottom: 50px;
    width: 200px;
    height: 40px;
    border: none;
    border-radius: 15px;
    background-color: #EA392C;
    font-size: 16px; font-weight: 400; color:#ffffff;
}

.postConstructor-mdxEditor {
    width: 600px !important;
    background-color: #ebebeb;
    cursor: text;
    text-align: center;
}

._popupContainer_uazmk_1220 {
    z-index: 100 !important;
}

._imageWrapper_uazmk_922 img {
    max-width: 100% !important;
}