/* createstudentList */
.studentList {
    position: fixed;
    z-index: 50;
    width: 100vw;
    height: 100%;
    left: 0;
    top: 0;
    overflow: auto;
    background-color: rgba(
        0,
        0,
        0,
        0.4
    );
}

.studentList-close {
    z-index: 50;
    position: absolute;
    height: 100%;
    width: 100%;
}

.studentList-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: fit-content;
    z-index: 51;
}

.studentList-ramka {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    background-color: white;
    padding-top: 10px;
    padding-bottom: 30px;
    width: 850px;
    min-height: 400px;
    z-index: 51;
}

.studentList h1 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.studentList ul {
    list-style: none;
    padding: 0;
}

.studentList li {
    background: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.studentList li:last-child {
    margin-bottom: 0;
}

.studentList li span {
    font-size: 16px;
    color: #555;
    min-width: 150px;
}

.studentList li span:nth-child(1) {
    margin-left: 20px;
}

.studentList li span:nth-child(2) {
    text-align: center;
    width: 300px;
}

.studentList li span:nth-child(3) {
    margin-right: 20px;
    text-align: center;
}

.studentList li button {
    margin-left: 10px;
    margin-right: 10px;
    width: 60px;
    font-size: 14px; font-weight: 600; color:#ffffff;
    cursor: pointer;
    height: 30px;
    background-color: #EA392C;
    border-radius: 5px;
    border: none;
}

.studentList li button:hover {
    background-color: #ea392cb3;
}

.studentList-title {
    font-weight: 700;
}

.error-message {
    color: red;
    margin-top: 10px;
    text-align: center;
}
