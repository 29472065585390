.calc {
  position: fixed;
  z-index: 50;
  width: fit-content;
  height: fit-content;
  right: 40%;
  top: 10%;
  overflow: auto;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border: 1px solid rgb(204, 204, 204);
}

.calc-close {
  background-color: #c3c3c3;
  display: flex;
  justify-content: center;
  height: 30px;
}

.calc-close button {
  width: 100px;
  height: 100%;
  border: none;
  background-color: #f6f8f9;
  margin-left: 235px;
}

.screen {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.expression {
  font-size: 1.2rem;
  color: #999;
  text-align: right;
}

.result {
  font-size: 2rem;
  color: #000;
  text-align: right;
}


.wrapper {
  background: #f6f8f9;
  width: fit-content;
  padding: 2rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  box-shadow: 0px 9px 15px -3px rgba(0,0,0,0.1);
}

.buttonBox {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 5 columns */
  gap: .5rem;
}

.button {
  height: 35px;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #242424;
  border: none;
  font-size: 16px;
}

.button.active {
  background-color: #4caf50; /* Green background for active state */
  color: white;
}

.number {
  background: #e9f0f4;
}

.operator {
  background: #f79505;
}

.equals {
  background: #4bd086;
  grid-column: span 2; /* span 2 columns for the equals button */
}

.button:hover {
  background: #f796054b;
}
