.subTab-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin-top: 10px;
    z-index: 10;
    cursor: pointer;
}

.subTab {
    min-width: 450px;
    height: 35px;
    border-radius: 7px;
    z-index: 10;
    background-color: rgba(234, 57, 44);
     font-size: 20px; font-weight: 500; color:#4F4F4F;
}

.subTab-trans {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.subTab-arrow-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    right: 10px;
    height: 80px;
    width: 1px;
    border-top-left-radius: 17px;
    border-top-right-radius: 17px;
    user-select: none;
}

.subTab-image-title {
    width: 85%;
    text-align: center;
}

.subTab-arrow-wrapper-image-up {
    width: 15px;
    transition-duration: 0s;
    user-select: none;
}

.subTab-arrow-wrapper-image-down {
    width: 15px;
    transform: rotate(180deg);
    transition-duration: 0s;
}

.subTab-add {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;font-weight: 500; color:#ffffff;
    width: fit-content;
    padding: 3px;
    background-color: rgb(234, 57, 44, 0.3);
    border-radius: 15px;
    margin-left: 150px;
    margin-top: 10px;
    transition: 0.3s;
    cursor: pointer;
}

.subTab-add:hover {
    background-color: #EA392C;
}

.subTab-minus {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;font-weight: 500; color:#ffffff;
    width: fit-content;
    padding: 3px;
    background-color: rgb(234, 57, 44, 0.3);
    border-radius: 15px;
    margin-left: 140px;
    transition: 0.3s;
    cursor: pointer;
}

.subTab-minus:hover {
    background-color: #EA392C;
}
