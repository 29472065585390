.addPost {
    width: 281px;
    height: 137px;
    -webkit-box-shadow: 0px 2px 20px -11px rgba(77,77,77,1);
    -moz-box-shadow: 0px 2px 20px -11px rgba(77,77,77,1);
    box-shadow: 0px 2px 20px -11px rgba(77,77,77,1);
    margin-top: 42px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.addPost div {
    font-size: 20px; font-weight: 400; color:#4F4F4F;
    margin-bottom: 15px;
}

.addPost button {
    width: 176px;
    height: 47px;
    background-color: #EA392C;
    border-radius: 15px;
    border: none;
    font-size: 20px; font-weight: 400; color:#ffffff;
}

.postConstructor-background {
    z-index: 3;
    position: fixed;
    height: 100%;
    width: 100%;
    overflow: hidden;
}