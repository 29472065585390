.tabStyleWrapper {
    width: inherit;
    margin-left: 20px;
    min-height: 10px;
    margin-bottom: 20px;
    margin-top: 27px;
    height: fit-content;
    border-bottom: 1px solid #a8a8a8;
}

.tabStyle {
     font-size: 20px; font-weight: 300; color:#000000;
}

.tabStyle a {
    cursor: pointer;
}

.tab-mark {
    width: 162px;
    height: 32px;
    background-color: white;
    border: 1px solid #4F4F4F;
    color: #4F4F4F;
    margin-top: 10px;
    margin-bottom: 20px;
}

.tab-mark:hover {
    background-color: #44944A;
    color: white;
}

.tab-mark-active {
    background-color: #44944A;
    color: white;
    border: 1px solid #44944A;
}

.tab-lastchild {
    border-bottom: none;
}

.hidden {
    display: none;
}

.tabStyle img {
    width: 20px;
}

.tabStyle-date {
    color: #787878;
    font-size: 16px;
}

.tab-popUp {
    position: fixed;
    z-index: 50;
    width: 100vw;
    height: 100%;
    left: 0;
    top: 0;
    overflow: auto;
    background-color: rgba(
        0,
        0,
        0,
        0.4
    );
}

.tab-popUp-close {
    z-index: 50;
    position: absolute;
    height: 100%;
    width: 100%;
}

.tab-popUp-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 51;
}

.tab-popUp-ramka {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background-color: white;
    width: 630px;
    height: 300px;
    z-index: 51;
}

.tab-popUp label {
    font-size: 20px;
    color: #555;
    margin-bottom: 20px;
    width: 150px;
}

.tab-popUp input {
    padding: 10px;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.tab-popUp button {
    padding: 10px 15px;
    margin-top: 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.tab-date-button {
    width: 50px;
    font-size: 13px; font-weight: 600; color:#ffffff;
    cursor: pointer;
    height: 25px;
    background-color: #EA392C;
    border-radius: 8px;
    border: none;
}

.tab-date-button:hover {
    background-color: #ea392cb3;
}

.tabStyle-quiz-close {
    color: #787878 !important;
    cursor: default !important;
}