/* Calendar.css */
.calendar {
    width: 100vw;
    max-width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .calendar-dashboard {
    margin-top: 55px;
  }
  
  .calendar-wrapper {
    width: 1030px;
    height: 600px;
    -webkit-box-shadow: 0px 2px 20px -11px rgba(77,77,77,1);
    -moz-box-shadow: 0px 2px 20px -11px rgba(77,77,77,1);
    box-shadow: 0px 2px 20px -11px rgba(77,77,77,1);
    border-radius: 30px;
    margin-left: 46px;
    margin-top: 55px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

.calendar-addEvent{
    font-size: 20px;font-weight: 400;color: #ffffff;
    width: 120px;
    height: 45px;
    position: relative;
    left: 30px;
    top: 20px;
    background-color: #EA392C;
    border-radius: 15px;
    border: none;
}

.calendar-addEvent:hover{
    background-color: #b83a31cf;
}
  
  .rbc-month-view .rbc-month-row .rbc-day-bg:hover {
    background-color: rgba(0, 123, 255, 0.1); /* Light blue background color with transparency */
    transition: background-color 0.2s ease-out; /* Smooth transition effect */
  }
  
  .rbc-month-view .rbc-month-row .rbc-day-bg {
    position: relative;
    min-height: 100px; /* Adjust as needed */
  }
  
  .rbc-month-view .rbc-month-row .rbc-day-content {
    overflow: auto;
  }
  
  .rbc-month-view .rbc-month-row .rbc-day-content .rbc-event {
    padding: 5px;
    box-sizing: border-box;
  }
  
  /* Ensure event titles are visible without times */
  .rbc-event-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .rbc-event-label {
    display: none; /* Hide the time labels */
  }
  
  .calendar-mdxEditor {
    background-color: #ebebeb;
    cursor: text;
  }

.calendar-event-mdxEditor {
  background-color: #f1f1f1;
  pointer-events: none;
}
