.header {
    width: 100vw;
    max-width: 100%;
    height: 60px;
    border-bottom: 1px solid #EA392C;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 12;
}

.header-logo {
    margin-right: 450px;
}

.header-logo img {
    width: 80px;
}

.header-dropdown {
    margin-right: 430px;
    position: relative;
    display: inline-block;
    height: 20px;
    z-index: 12;
}

.header-dropdown p {
    font-size: 16px; font-weight: 500; color:#8B8B8B;
    width: 120px;
    height: 20px;
}

.header-arrow {
    margin-left: 5px;
    margin-bottom: 2px;
}

.header-dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 100px;
    margin-top: -10px;
    border: 1px solid #8B8B8B;
    z-index: 12;
}

.header-dropdown-content-text {
    font-size: 14px; font-weight: 400; color:#000000;
    user-select: none;
    display: flex;
    align-items: center;
    padding-left: 10px;
    height: 30px;
    width: 100px;
}

.header-dropdown-content-text:hover {
    background-color: #cacaca;
}

.header-dropdown:hover .header-dropdown-content {
    display: block;
}

.header-addStudent {
    width: 230px;
    font-size: 16px; font-weight: 600; color:#ffffff;
    cursor: pointer;
    height: 45px;
    background-color: #EA392C;
    border-radius: 15px;
    border: none;
}    

.header-addStudent:hover {
    background-color: #ea392cb3;
}

.header-addNotification {
    width: 170px;
    font-size: 16px; font-weight: 600; color:#ffffff;
    cursor: pointer;
    height: 45px;
    background-color: #EA392C;
    border-radius: 15px;
    border: none;
    margin-left: 150px;
}    

.header-addNotification:hover {
    background-color: #ea392cb3;
}

.header-ring {
    width: 16px;
    margin-left: 20px;
    cursor: pointer;
}

.notification-dropdown {
    position: absolute;
    top: 50px;
    right: 100px;
    width: 250px;
    border: 1px solid #ddd;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .notification-dropdown button {
    width: 80px;
    font-size: 14px; font-weight: 600; color:#ffffff;
    cursor: pointer;
    height: 25px;
    background-color: #EA392C;
    border-radius: 10px;
    border: none;
  }

  .notification-dropdown button:hover {
    background-color: #ea392cb3;
}
  
  .notification-dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .notification-dropdown li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .notification-dropdown li:last-child {
    border-bottom: none;
  }
  
  .notification-dropdown p {
    padding: 10px;
    margin: 0;
  }

  .notification-message {
    background-color: #8b8b8b32;
    height: 40px;
    font-size: 14px; font-weight: 400; color:#000000;
  }

.header-profile {
    display: flex;
    align-items: center;
    width: fit-content;
    height: fit-content;
    margin-left: 15px;
}

.forStudent {
    margin-left: 600px !important;
}

.header-username {
    font-size: 16px; font-weight: 500; color:#EA392C;
}

.header-profile-photo {
    margin-left: 10px;
}

.header-profile-arrow {
    margin-left: 5px;
    margin-top: -5px;
}

.header-profile:hover .header-profile-dropdown-content {
    display: block;
}

.header-profile-dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 140px;
    margin-top: 100px;
    border: 1px solid #8B8B8B;
    z-index: 12;
}

.header-profile-dropdown-content div:nth-child(1) {
    border-bottom: 1px solid #8B8B8B;
}

.header-profile-dropdown-content-text {
    font-size: 14px; font-weight: 400; color:#000000;
    user-select: none;
    display: flex;
    align-items: center;
    padding-left: 10px;
    height: 30px;
    width: 140px;
}

.header-profile-dropdown-content-text:hover {
    background-color: #ea392c4b;
}

.header-login {
    font-size: 16px; font-weight: 500; color:#000000;
    margin-left: 450px;
}

.header-login:hover {
    color: #EA392C;
}

.header-registration {
    font-size: 16px; font-weight: 500; color:#000000 ;
    margin-left: 2%;
}

.header-registration:hover {
    color: #EA392C;
}

.header a {
    color: inherit !important;
    text-decoration: inherit !important;
}