.addQuestion {
    position: fixed;
    z-index: 50;
    width: 100vw;
    height: 100%;
    left: 0;
    top: 0;
    overflow: auto;
    background-color: rgba(
        0,
        0,
        0,
        0.4
    );
}

.addQuestion-close {
    z-index: 50;
    position: absolute;
    height: 100%;
    width: 100%;
}

.addQuestion-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 51;
}

.addQuestion-ramka {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    border-radius: 15px;
    background-color: white;
    width: 600px;
    min-height: 600px;
    z-index: 51;
}
  
  .addQuestion h1 {
    text-align: center;
  }
  
  .addQuestion-form {
    width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .addQuestion-form label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
.addQuestion-form textarea {
  resize: none;
  width: 100%;
}

  .addQuestion-form input[type="text"],
  .addQuestion-form input[type="number"],
  .addQuestion-form input[type="datetime-local"],
  .addQuestion-form input[type="file"] {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .addQuestion-form-button {
    display: inline-block;
    padding: 10px 15px;
    font-size: 14px;
    margin-top: 10px;
    font-weight: bold;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .addQuestion-form-button:hover {
    background-color: #0056b3;
  }
  
  .question-section {
    border-top: 1px solid #ccc;
    padding-top: 20px;
    margin-top: 20px;
  }
  
  .option-section {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
  }
  
  .upload-section {
    margin-bottom: 20px;
  }
  
.addQuestion-mdxEditor {
    background-color: rgb(230, 230, 230);
    width: 100% !important;
    cursor: text;
    text-align: center;
}

._popupContainer_uazmk_1220 {
    z-index: 100 !important;
}

._imageWrapper_uazmk_922 img {
    max-width: 100% !important;
}