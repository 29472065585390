.slide-in-post {
  margin-top: -250px;
  opacity: 0;
  transition-property: margin-top, opacity;
  transition-duration: 0.5s;
}

.slide-in-tab {
  margin-top: -200px;
  opacity: 0;
  transition-property: margin-top, opacity;
  transition-duration: 0.5s;
}