.subject-header {
    margin-top: 55px;
    width: 1030px;
    height: 164px;
    border-radius: 30px;
    -webkit-box-shadow: 0px 2px 20px -11px rgba(77,77,77,1);
    -moz-box-shadow: 0px 2px 20px -11px rgba(77,77,77,1);
    box-shadow: 0px 2px 20px -11px rgba(77,77,77,1);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.subject-header-title {
    margin-left: 43px;
     font-size: 32px; font-weight: 400; color:#000000;
    padding-bottom: 5px;
}

.subject-header-breadcrumbs {
    margin-left: 43px;
}

.subject-header a:hover {
    text-decoration: underline !important;
}

.subject-header a{
    cursor: pointer;
}