.createStudent-page {
    position: fixed;
    z-index: 50;
    width: 100vw;
    height: 100%;
    left: 0;
    top: 0;
    overflow: auto;
    background-color: rgba(
        0,
        0,
        0,
        0.4
    );
}

.createStudent-close {
    z-index: 50;
    position: absolute;
    height: 100%;
    width: 100%;
}

.createStudent-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 51;
}

.createStudent-ramka {
    display: flex;
    justify-content: center;
    z-index: 51;
    width: 780px;
    min-height: 980px;
    border-radius: 30px;
    -webkit-box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
    background-color: #FFFFFF;
}

.createStudent-content {
    display: flex;
    flex-direction: column;
    width: 640px;
    height: fit-content;
}

.createStudent-title {
    align-self: baseline;
    margin-top: 60px;
     font-size: 40px;font-weight: 500;color: #000000;
}

input:focus {
    outline: none !important;
    border: 1px solid #AB2A3B;
}

.createStudent-email {
    width: 100%;
    height: 83px;
    margin-top: 32px;
}

.createStudent-bar-text {
     font-size: 20px;font-weight: 400;color: #000000;
}

.createStudent-Bar {
    padding-left: 20px;
    width: 615px;
    height: 45px;
    background-color: transparent;
    border: 1px solid #B7B7B7;
    border-radius: 10px;
     font-size: 20px;font-weight: 400;
    margin-top: 22px;
}

.createStudent-password {
    width: 100%;
    height: 83px;
    margin-top: 30px;
}

.createStudent-password-text {
     font-size: 20px;font-weight: 400;color: #000000;
}

.createStudent-button {
    margin-top: 55px;
    width: 640px;
    height: 50px;
    border-radius: 10px;
    background-color: #EA392C;
    border: none;
     font-size: 20px;font-weight: 400;color: #FFFFFF;
    transition: all 300ms ease;
}

.createStudent-button:hover {
    background-color: #EA392C;
    cursor: pointer;
    transition: all 300ms ease;
}

/* .createStudent-password-recover {
    align-self: center;
    position: relative;
     font-size: 16px;font-weight: 400;color: #EA392C;
    top: 73px;
} */

.createStudent-page a {
    color: #EA392C;
}

.createStudent-empty {
    color: #DD8A8A;
}

.createStudent-incorrectBar {
    border-color: #DD8A8A;
}

.createStudent-email-incorrect {
    height: 20px;
    position: relative;
    top: 20px;
    text-align: center;
    font-size: 18px;font-weight: 400;color: #ff00008c;
}

.createStudent-success {
    height: 20px;
    position: relative;
    top: 20px;
    text-align: center;
    font-size: 24px;font-weight: 400;color: #7fec7a;
}

@media screen and (max-width: 1200px) {
    
    .createStudent-page {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 886px;
        width: 100vw;
        max-width: 100%;
    }
    
    .createStudent-ramka {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        max-width: 720px;
        height: 706px;
        border-radius: 30px;
        -webkit-box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
        box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
        background-color: #FFFFFF;
    }
    
    .createStudent-createStudento {
        position: relative;
        margin-top: 32px;
        width: 70px;
    }
    
    .createStudent-text {
        position: relative;
        top: 23px;
         font-size: 20px;font-weight: 400;color: #000000;
        text-align: center;
    }
    
    .createStudent-inputs {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 399px;
        top: 50px;
    }
    
    .createStudent-email-text {
        align-self: baseline;
         font-size: 20px;font-weight: 400;color: #000000;
    }
    
    input:focus {
        outline: none !important;
        border: 1px solid #AB2A3B;
    }
    
    .createStudent-email {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        width: 80%;
        height: 83px;
    }
    
    .createStudent-emailBar {
        position: relative;
        padding-left: 20px;
        width: 100%;
        height: 40px;
        background-color: transparent;
        border: 1px solid #B7B7B7;
        border-radius: 10px;
         font-size: 20px;font-weight: 400;
        top: 5px;
    }
    
    .createStudent-password {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        width: 80%;
        height: 83px;
        top: 10px;
    }
    
    .createStudent-password-text {
        align-self: baseline;
         font-size: 20px;font-weight: 400;color: #000000;
    }
    
    .createStudent-passwordBar {
        position: relative;
        padding-left: 20px;
        width: 100%;
        height: 40px;
        background-color: transparent;
        border: 1px solid #B7B7B7;
        border-radius: 10px;
         font-size: 20px;font-weight: 400;
        top: 5px;
    }
    
    .createStudent-button {
        position: relative;
        align-self: center;
        top: 40px;
        width: 90%;
        height: 52px;
        border-radius: 10px;
        background-color: #AB2A3B;
        border: none;
         font-size: 20px;font-weight: 400;color: #FFFFFF;
        transition: all 300ms ease;
    }
    
    .createStudent-button:hover {
        background-color: #FFDF2C;
        transition: all 300ms ease;
        cursor: pointer;
    }
    
    .createStudent-password-recover {
        align-self: center;
        position: relative;
         font-size: 16px;font-weight: 400;color: #AB2A3B;
        top: 50px;
    }

    .createStudent-to-reg-text {
        position: relative;
        top: 80px;
        align-self: center;
        text-align: center;
        width: 100%;
         font-size: 17px;font-weight: 400;color: #000000;
    }

    .createStudent-to-reg {
        position: relative;
        align-self: center;
        text-align: center;
        top: 90px;
        height: 0;
        width: 80%;
        border-bottom: 1px solid #AB2A3B;
    }
    
    .createStudent-google {
        position: relative;
        align-self: center;
        display: flex;
        align-items: center;
        top: 115px;
        width: 80%;
        height: 49px;
        border: 1px solid #B7B7B7;
        border-radius: 10px;
    }
    
    .createStudent-google p {
        position: relative;
         font-size: 18px;font-weight: 400;color: #000000;
        left: 28px;
    }
    
    .createStudent-google-createStudento {
        position: relative;
        left: 13px;
    }
    
    .createStudent-page a {
        color: #AB2A3B;
    }
    
    .incorrect {
        color: #DD8A8A;
    }
    
    .incorrectBar {
        border-color: #DD8A8A;
    }
    
    .createStudent-email-incorrect {
        height: 20px;
        position: relative;
        top: 60px;
        text-align: center;
         font-size: 14px;font-weight: 400;color: #DD8A8A;
    }

}