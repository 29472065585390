.demoSAT {
    width: 100vw;
    max-width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.demoSAT-dashboard{
    margin-top: 55px;
}

.demoSAT-content-wrapper {
    margin-left: 45px;
}

.demoSAT-add {
    width: 200px;
    margin-top: 20px;
    font-size: 16px; font-weight: 600; color:#ffffff;
    cursor: pointer;
    height: 45px;
    background-color: #EA392C;
    border-radius: 15px;
    border: none;
}

.demoSAT-add:hover {
    background-color: #ea392cb3;
}

.demoSAT-content {
    width: 1030px;
    min-height: 1370px;
    -webkit-box-shadow: 0px 2px 20px -11px rgba(77,77,77,1);
    -moz-box-shadow: 0px 2px 20px -11px rgba(77,77,77,1);
    box-shadow: 0px 2px 20px -11px rgba(77,77,77,1);
    border-radius: 30px;
    margin-top: 20px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.demoSAT-content-statistics {
    width: 950px;
    margin-left: 20px;
    margin-top: 32px;
}

.demoSAT-content-statistics p{
    font-size: 20px; font-weight: 600; color:#4F4F4F;
}

.demoSAT-content-statistics-graph{
    display: flex;
    flex-direction: column;
    width: 552px;
    height: 242px;
    border-radius: 24px;
    -webkit-box-shadow: 0px 0px 18px -9px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 18px -9px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 18px -9px rgba(0,0,0,0.75);
}

.statistics-graph-title {
    display: flex;
    align-items: center;
    padding-left: 32px;
    width: 100%;
    height: 78px;
    background-color: #EA392C;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    font-size: 40px; font-weight: 600; color:white;
}

.statistics-graph-items-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 164px;
}

.statistics-graph-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    height: 80%;
}

.statistics-graph-item:nth-child(1) {
    border-right: 1px solid #E3E3E3;
}

.statistics-graph-item div:nth-child(1){
    font-size: 14px; font-weight: 600; color:rgb(0, 0, 0);
}

.statistics-graph-item div:nth-child(2){
    margin-top: 10px;
    font-size: 48px; font-weight: 600; color:rgb(0, 0, 0);
}

.statistics-graph-item div:nth-child(3){
    margin-top: 10px;
    font-size: 12px; font-weight: 600; color:rgb(0, 0, 0);
}

.demoSAT-content-statistics button{
    width: 217px;
    height: 56px;
    margin-top: 24px;
    background-color: #EA392C;
    color: white;
    border-radius: 15px;
    border: none;
}

.demoSAT-content-statistics button:hover{
    background-color: #ea392cb6;
}

.demoSAT-content-practices {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 50px;
}


