.course-content-lessons-open-close {
    margin-top: 20px;
    display: flex;
    align-self: baseline;
}

.course-content-lessons-open-close-button {
    font-size: 18px; font-weight: 500; color:#4F4F4F;
    margin-left: 30px;
    cursor: pointer;
}

.course-content-lessons-instruction {
    position: relative;
    font-size: 18px; font-weight: 400; color:#4F4F4F;
    top: 8px;
    right: 185px;
}

.course-content-tabs {
    margin-top: 20px;
}

.course-content-lessons::-webkit-scrollbar {
    height: 7px;
    width: 7px;
}

.course-content-lessons::-webkit-scrollbar-track {
    border-radius: 9px;
    background-color: transparent;
}

.course-content-lessons::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 15px;
}

.course-content-lessons::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 15px;
}
  
.course-content-lessons::-webkit-scrollbar-track:hover {
    background-color: transparent;
}
  
.course-content-lessons::-webkit-scrollbar-track:active {
    background-color: transparent;
}
  
.course-content-lessons::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #D2D2D2;
}
  
.course-content-lessons::-webkit-scrollbar-thumb:hover {
    background-color: #D2D2D2;
}
  
.course-content-lessons::-webkit-scrollbar-thumb:active {
    background-color: #D2D2D2;
}

.course-add {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;font-weight: 500; color:#ffffff;
    width: fit-content;
    padding: 3px;
    background-color: rgb(234, 57, 44, 0.3);
    border-radius: 15px;
    margin-top: 20px;
    transition: 0.3s;
    cursor: pointer;
}

.course-add:hover {
    background-color: #EA392C;
}