.log-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 800px;
    width: 100vw;
    max-width: 100%;
}

.log-ramka {
    display: flex;
    justify-content: center;
    width: 780px;
    height: 550px;
    border-radius: 30px;
    -webkit-box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
    background-color: #FFFFFF;
}

.log-content {
    display: flex;
    flex-direction: column;
    width: 640px;
    height: fit-content;
}

.log-title {
    align-self: baseline;
    margin-top: 60px;
     font-size: 40px;font-weight: 500;color: #000000;
}

input:focus {
    outline: none !important;
    border: 1px solid #AB2A3B;
}

.log-email {
    width: 100%;
    height: 83px;
    margin-top: 32px;
}

.log-bar-text {
     font-size: 20px;font-weight: 400;color: #000000;
}

.log-Bar {
    padding-left: 20px;
    width: 615px;
    height: 45px;
    background-color: transparent;
    border: 1px solid #B7B7B7;
    border-radius: 10px;
     font-size: 20px;font-weight: 400;
    margin-top: 22px;
}

.log-password {
    width: 100%;
    height: 83px;
    margin-top: 30px;
}

.log-password-text {
     font-size: 20px;font-weight: 400;color: #000000;
}

.log-button {
    margin-top: 55px;
    width: 640px;
    height: 50px;
    border-radius: 10px;
    background-color: #EA392C;
    border: none;
     font-size: 20px;font-weight: 400;color: #FFFFFF;
    transition: all 300ms ease;
}

.log-button:hover {
    background-color: #EA392C;
    cursor: pointer;
    transition: all 300ms ease;
}

/* .log-password-recover {
    align-self: center;
    position: relative;
     font-size: 16px;font-weight: 400;color: #EA392C;
    top: 73px;
} */

.log-to-reg {
    align-self: center;
    text-align: center;
    height: 10px;
    width: 320px;
     font-size: 20px;font-weight: 400;color: #000000;
    border-bottom: 1px solid #EA392C;
}

.log-to-reg-text {
    margin-top: 29px;
    align-self: center;
    text-align: center;
    width: 400px;
     font-size: 20px;font-weight: 400;color: #000000;
}

.log-google {
    display: flex;
    align-items: center;
    align-self: center;
    margin-top: 36px;
    width: 640px;
    height: 49px;
    border: 1px solid #B7B7B7;
    border-radius: 10px;
}

.log-google p {
    margin-bottom: inherit;
     font-size: 20px;font-weight: 400;color: #000000;
    margin-left: 28px;
}

.log-google-logo {
    position: relative;
    left: 13px;
}

.log-page a {
    color: #EA392C;
}

.log-empty {
    color: #DD8A8A;
}

.log-incorrectBar {
    border-color: #DD8A8A;
}

.log-email-incorrect {
    height: 20px;
    position: relative;
    top: 10px;
    text-align: center;
     font-size: 18px;font-weight: 400;color: #DD8A8A;
}

@media screen and (max-width: 1200px) {
    
    .log-page {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 886px;
        width: 100vw;
        max-width: 100%;
    }
    
    .log-ramka {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        max-width: 720px;
        height: 706px;
        border-radius: 30px;
        -webkit-box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
        box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
        background-color: #FFFFFF;
    }
    
    .log-logo {
        position: relative;
        margin-top: 32px;
        width: 70px;
    }
    
    .log-text {
        position: relative;
        top: 23px;
         font-size: 20px;font-weight: 400;color: #000000;
        text-align: center;
    }
    
    .log-inputs {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 399px;
        top: 50px;
    }
    
    .log-email-text {
        align-self: baseline;
         font-size: 20px;font-weight: 400;color: #000000;
    }
    
    input:focus {
        outline: none !important;
        border: 1px solid #AB2A3B;
    }
    
    .log-email {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        width: 80%;
        height: 83px;
    }
    
    .log-emailBar {
        position: relative;
        padding-left: 20px;
        width: 100%;
        height: 40px;
        background-color: transparent;
        border: 1px solid #B7B7B7;
        border-radius: 10px;
         font-size: 20px;font-weight: 400;
        top: 5px;
    }
    
    .log-password {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        width: 80%;
        height: 83px;
        top: 10px;
    }
    
    .log-password-text {
        align-self: baseline;
         font-size: 20px;font-weight: 400;color: #000000;
    }
    
    .log-passwordBar {
        position: relative;
        padding-left: 20px;
        width: 100%;
        height: 40px;
        background-color: transparent;
        border: 1px solid #B7B7B7;
        border-radius: 10px;
         font-size: 20px;font-weight: 400;
        top: 5px;
    }
    
    .log-button {
        position: relative;
        align-self: center;
        top: 40px;
        width: 90%;
        height: 52px;
        border-radius: 10px;
        background-color: #AB2A3B;
        border: none;
         font-size: 20px;font-weight: 400;color: #FFFFFF;
        transition: all 300ms ease;
    }
    
    .log-button:hover {
        background-color: #FFDF2C;
        transition: all 300ms ease;
        cursor: pointer;
    }
    
    .log-password-recover {
        align-self: center;
        position: relative;
         font-size: 16px;font-weight: 400;color: #AB2A3B;
        top: 50px;
    }

    .log-to-reg-text {
        position: relative;
        top: 80px;
        align-self: center;
        text-align: center;
        width: 100%;
         font-size: 17px;font-weight: 400;color: #000000;
    }

    .log-to-reg {
        position: relative;
        align-self: center;
        text-align: center;
        top: 90px;
        height: 0;
        width: 80%;
        border-bottom: 1px solid #AB2A3B;
    }
    
    .log-google {
        position: relative;
        align-self: center;
        display: flex;
        align-items: center;
        top: 115px;
        width: 80%;
        height: 49px;
        border: 1px solid #B7B7B7;
        border-radius: 10px;
    }
    
    .log-google p {
        position: relative;
         font-size: 18px;font-weight: 400;color: #000000;
        left: 28px;
    }
    
    .log-google-logo {
        position: relative;
        left: 13px;
    }
    
    .log-page a {
        color: #AB2A3B;
    }
    
    .incorrect {
        color: #DD8A8A;
    }
    
    .incorrectBar {
        border-color: #DD8A8A;
    }
    
    .log-email-incorrect {
        height: 20px;
        position: relative;
        top: 60px;
        text-align: center;
         font-size: 14px;font-weight: 400;color: #DD8A8A;
    }

}