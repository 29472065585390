.dashboard {
    width: 284px;
    min-height: 108px;
    -webkit-box-shadow: 0px 0px 10px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 10px -3px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 10px -3px rgba(0,0,0,0.75);
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dashDown {
    margin-top: 30px;
    border-radius: 0;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.dashUp {
    border-radius: 0;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.dashboard-section {
    display: flex;
    align-items: center;
    width: 100%;
    height: 54px;
    font-size: 18px; font-weight: 400; color:#6A6A6A;
    border-bottom: 1px solid #999999;
    cursor: pointer;
}

.dashboard-section:hover {
    background-color: #e0e0e0 !important;
    color: white !important;
}

.dashboard-section:nth-child(1):hover {
    background-color: #EA392C !important;
    color: white !important;
}

.dashboard-section span {
    margin-left: 15px;
}

.dashboard-icons {
    margin-left: 30px;
}

.dashboard-section:nth-child(1) .dashboard-icons{
    margin-left: 31px;
}

.dashboard-section:nth-child(2) .dashboard-icons{
    margin-left: 33px;
}

.dashboard-section:nth-child(3) .dashboard-icons{
    margin-left: 33px;
}
.dashboard-section:nth-child(4) .dashboard-icons{
    margin-left: 33px;
}

.dashboard-section:first-child {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.dashboard-active {
    background-color: #EA392C;
    color: white;
    border-bottom: none;
}

.dashDown .dashboard-section:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: white;
    color: #6A6A6A;
    border-bottom: 1px solid #999999;
}

.dashboard-section:last-child {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    border-bottom: none;
}

.dashUp .dashboard-section:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
}

.useful-button {
    width: 80px;
    font-size: 13px; font-weight: 600; color:#ffffff;
    cursor: pointer;
    height: 25px;
    background-color: #EA392C;
    border-radius: 10px;
    margin-left: 40px;
    border: none;
}