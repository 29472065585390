.lessonMaker-page {
    position: fixed;
    z-index: 50;
    width: 100vw;
    height: 100%;
    left: 0;
    top: 0;
    overflow: auto;
    background-color: rgba(
        0,
        0,
        0,
        0.4
    );
}

.lessonMaker-close {
    z-index: 50;
    position: absolute;
    height: 100%;
    width: 100%;
}

.lessonMaker-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 51;
}

.lessonMaker-group {
    width: calc(100% - 22px);
    padding: 10px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.lessonMaker-ramka {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    background-color: white;
    width: 600px;
    height: 400px;
    z-index: 51;
}

.lessonMaker-title {
    margin-top: 20px;
    font-size: 28px;font-weight: 600; color:#000000;
}

.lesson-maker-form {
    width: 600px;
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.lesson-maker-form div {
    margin-bottom: 15px;    
}

.lesson-maker-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.lesson-maker-form input {
    width: calc(100% - 22px);
    padding: 10px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.lesson-maker-form button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    margin-right: 10px;
}

.lesson-maker-form button:hover {
    background-color: #0056b3;
}

.lessonMaker-fail {
    text-align: center;
    font-size: 18px;font-weight: 400;color: #DD8A8A;
}