/* Practice Test Container */
.practice-test {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: fixed;
    background-color: white;
    z-index: 100;
    height: 100%;
  }
  
  .practice-test__title {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .practice-test__subtitle {
    color: #666;
    margin-bottom: 20px;
  }
  
  .practice-test__info {
    margin-top: 20px;
    text-align: left;
  }
  
  /* Info Item Styling */
  .practice-test__info-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  
  .practice-test__icon {
    font-size: 1.5rem;
    color: #FF6666;
    margin-right: 15px;
  }
  
  .practice-test__info-content {
    display: flex;
    flex-direction: column;
  }
  
  .practice-test__info-title {
    font-size: 1.2rem;
    margin: 0;
    color: #333;
  }
  
  .practice-test__info-text {
    font-size: 0.9rem;
    color: #666;
    margin: 5px 0 0;
    width: 600px;
  }
  
  /* Start Button Styling */
  .practice-test__start-button {
    background-color: #FF4C4C;
    color: #FFF;
    border: none;
    padding: 10px 20px;
    width: 200px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 20px;
    transition: background-color 0.3s ease;
  }
  
  .practice-test__start-button:hover {
    background-color: #FF6666;
  }
  