/* createGroup.css */
.create-group {
    position: fixed;
    z-index: 50;
    width: 100vw;
    height: 100%;
    left: 0;
    top: 0;
    overflow: auto;
    background-color: rgba(
        0,
        0,
        0,
        0.4
    );
}

.create-group-close {
    z-index: 50;
    position: absolute;
    height: 100%;
    width: 100%;
}

.create-group-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 51;
}

.create-group-ramka {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    background-color: white;
    width: 650px;
    height: 400px;
    z-index: 51;
}

.create-group h1 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.create-group-form {
    display: flex;
    flex-direction: column;
}

.create-group-form div {
    margin-bottom: 15px;
}

.create-group-form label {
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
}

.create-group-form input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
}

.create-group-form button {
    padding: 10px 15px;
    margin-top: 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.create-group-form button:disabled {
    background-color: #aaa;
}

.success-message {
    color: green;
    margin-top: 10px;
    text-align: center;
}

.error-message {
    color: red;
    margin-top: 10px;
    text-align: center;
}
