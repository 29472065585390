.reg-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 946px;
    width: 100vw;
    max-width: 100%;
}

.reg-ramka {
    display: flex;
    justify-content: center;
    width: 780px;
    height: 715px;
    border-radius: 30px;
    -webkit-box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
    background-color: #FFFFFF;
}

.reg-content {
    display: flex;
    flex-direction: column;
    width: 640px;
    height: fit-content;
}

.reg-title {
    align-self: baseline;
    margin-top: 60px;
     font-size: 40px;font-weight: 500;color: #000000;
}

input:focus {
    outline: none !important;
    border-color: inherit;
}

.email {
    width: 100%;
    height: 83px;
    margin-top: 32px;
}

.password {
    width: 100%;
    height: 83px;
    margin-top: 30px;
}

.reg-bar-text {
     font-size: 20px;font-weight: 400;color: #000000;
}

.reg-bar {
    padding-left: 20px;
    width: 615px;
    height: 45px;
    background-color: transparent;
    border: 1px solid #B7B7B7;
    border-radius: 10px;
     font-size: 20px;font-weight: 400;
    margin-top: 22px;
}

.reg-button {
    margin-top: 55px;
    width: 640px;
    height: 50px;
    border-radius: 10px;
    background-color: #EA392C;
    border: none;
     font-size: 20px;font-weight: 400;color: #FFFFFF;
    transition: all 300ms ease;
}

.reg-button:hover {
    background-color: #EA392C;
    transition: all 300ms ease;
    cursor: pointer;
}

.reg-to-log {
    margin-top: 29px;
    align-self: center;
    text-align: center;
    width: 400px;
    height: 40px;
     font-size: 20px;font-weight: 400;color: #000000;
    border-bottom: 1px solid #EA392C;
}

.reg-google {
    display: flex;
    align-items: center;
    align-self: center;
    margin-top: 36px;
    width: 640px;
    height: 49px;
    border: 1px solid #B7B7B7;
    border-radius: 10px;
}

.reg-google p {
    position: relative;
     font-size: 20px;font-weight: 400;color: #000000;
    left: 28px;
}

.reg-google-logo {
    position: relative;
    left: 13px;
}

.reg-page a {
    color: #EA392C;
}

.incorrectBar {
    border-color: #e10000;
}

.reg-email-incorrect {
    height: 20px;
    position: relative;
    top: 60px;
    text-align: center;
     font-size: 18px;font-weight: 400;color: #DD8A8A;
}

@media screen and (max-width: 1200px) {
    
    .reg-page {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 886px;
        width: 100vw;
        max-width: 100%;
    }
    
    .reg-ramka {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        max-width: 720px;
        height: 706px;
        border-radius: 30px;
        -webkit-box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
        box-shadow: 0px 4px 40px -10px rgba(0,0,0,0.75);
        background-color: #FFFFFF;
    }
    
    .reg-logo {
        position: relative;
        margin-top: 32px;
        width: 70px;
    }
    
    .reg-text {
        position: relative;
        top: 23px;
         font-size: 20px;font-weight: 400;color: #000000;
        text-align: center;
    }
    
    .reg-inputs {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 399px;
        top: 50px;
    }
    
    .email-text {
        align-self: baseline;
         font-size: 20px;font-weight: 400;color: #000000;
    }
    
    input:focus {
        outline: none !important;
        border: 1px solid #AB2A3B;
    }
    
    .email {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        width: 80%;
        height: 83px;
    }
    
    .emailBar {
        position: relative;
        padding-left: 20px;
        width: 100%;
        height: 40px;
        background-color: transparent;
        border: 1px solid #B7B7B7;
        border-radius: 10px;
         font-size: 20px;font-weight: 400;
        top: 5px;
    }
    
    .password {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        width: 80%;
        height: 83px;
        top: 10px;
    }
    
    .password-text {
        align-self: baseline;
         font-size: 20px;font-weight: 400;color: #000000;
    }
    
    .passwordBar {
        position: relative;
        padding-left: 20px;
        width: 100%;
        height: 40px;
        background-color: transparent;
        border: 1px solid #B7B7B7;
        border-radius: 10px;
         font-size: 20px;font-weight: 400;
        top: 5px;
    }
    
    .reg-button {
        position: relative;
        align-self: center;
        top: 40px;
        width: 90%;
        height: 52px;
        border-radius: 10px;
        background-color: #AB2A3B;
        border: none;
         font-size: 20px;font-weight: 400;color: #FFFFFF;
        transition: all 300ms ease;
    }
    
    .reg-button:hover {
        background-color: #FFDF2C;
        transition: all 300ms ease;
        cursor: pointer;
    }
    
    .reg-to-log {
        position: relative;
        top: 80px;
        align-self: center;
        text-align: center;
        height: 39px;
        width: 80%;
         font-size: 20px;font-weight: 400;color: #000000;
        border-bottom: 1px solid #AB2A3B;
    }
    
    .reg-google {
        position: relative;
        align-self: center;
        display: flex;
        align-items: center;
        top: 110px;
        width: 80%;
        height: 49px;
        border: 1px solid #B7B7B7;
        border-radius: 10px;
    }
    
    .reg-google p {
        position: relative;
         font-size: 18px;font-weight: 400;color: #000000;
        left: 28px;
    }
    
    .reg-google-logo {
        position: relative;
        left: 13px;
    }
    
    .reg-page a {
        color: #AB2A3B;
    }
    
    .incorrect {
        color: #DD8A8A;
    }
    
    .incorrectBar {
        border-color: #DD8A8A;
    }
    
    .reg-email-incorrect {
        height: 20px;
        position: relative;
        top: 60px;
        text-align: center;
         font-size: 18px;font-weight: 400;color: #DD8A8A;
    }

}